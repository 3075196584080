import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";

let firebaseConfig;
let apiKey;
if (window.location.origin.includes("ai.brainsight.app")) {
  console.log("Running Production Environment");
  firebaseConfig = {
    apiKey: "AIzaSyAud_kxbGYajXq9gp82iXsOkFe-5JPRYzQ",
    authDomain: "ai.brainsight.app",
    projectId: "deepgaze-io",
    storageBucket: "deepgaze-io.appspot.com",
    messagingSenderId: "533670030851",
    appId: "1:533670030851:web:59c7fa9d5cf9d3a438e836",
    measurementId: "G-LVSEGT2QP9",
  };
  apiKey = "AIzaSyAud_kxbGYajXq9gp82iXsOkFe-5JPRYzQ";
} else {
  console.log("Running Staging Environment");
  firebaseConfig = {
    apiKey: "AIzaSyDs4p4OaFdzwX1rUf1_qSnQ92dlHdrsGfw",
    authDomain: "deepgaze-staging.firebaseapp.com",
    projectId: "deepgaze-staging",
    storageBucket: "deepgaze-staging.appspot.com",
    messagingSenderId: "749609371528",
    appId: "1:749609371528:web:074969731f82cafd959c3d",
    measurementId: "G-4BGD5J1DP0",
  };
  apiKey = "AIzaSyDs4p4OaFdzwX1rUf1_qSnQ92dlHdrsGfw";
}

firebase.initializeApp(firebaseConfig);
firebase.analytics();

let emulator = false;

if (emulator && window.location.origin.includes("localhost")) {
  console.log("Starting Functions Emulators");
  firebase.app().functions("europe-west1").useEmulator("localhost", 5001);
}

// utils
const db = firebase.firestore();
const auth = firebase.auth();
const usersCollection = db.collection("users");

// export utils/refs
export { db, auth, usersCollection, apiKey };
