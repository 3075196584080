<template>
  <div v-if="heatmaps && heatmaps.length > 0">
    <div
      v-if="
        heatmaps.findIndex((h) => h.type === 'video') >= 0 &&
        heatmaps.length > 1
      "
      class="d-flex px-6 pt-6 align-center"
      style="gap: 25px; height: 50px"
    >
      <v-switch
        label="Sync videos"
        class="ma-0 pa-0"
        hide-details=""
        v-model="sync"
        @change="changeSync"
      ></v-switch>
      <v-bottom-sheet
        hide-overlay
        :value="sync"
        inset
        persistent
        max-width="50vw"
        no-click-animation
        ref="sheet"
        @input="change"
        :retain-focus="false"
      >
        <v-card
          color="primary"
          dark
          class="pa-8 d-flex align-center"
          style="gap: 20px"
        >
          <v-btn large class="mr-2" icon @click="play"
            ><v-icon large>{{
              playing ? "mdi-pause" : "mdi-play"
            }}</v-icon></v-btn
          >
          <v-slider
            @change="setCurrentTime"
            :key="key"
            hide-details=""
            v-model="currentTime"
            :max="videoDuration"
            min="0"
            step="1"
          ></v-slider>
          <div style="width: 50px" class="d-flex flex-column align-center">
            <p class="ma-0 pa-0" style="font-size: 16pt; font-weight: 500">
              {{ currentTime != null ? Math.round(currentTime) : "-" }}
            </p>

            <p class="ma-0 pa-0" style="font-size: 10pt; font-weight: 400">
              {{ videoDuration ? Math.round(videoDuration) : "-" }}
            </p>
          </div>
        </v-card>
      </v-bottom-sheet>
    </div>
    <div class="wrapper">
      <div class="heatmap" v-for="(h, index) in heatmaps" :key="h.uid">
        <v-card outlined flat>
          <div class="d-flex justify-space-between align-center px-4">
            <v-btn
              icon="mdi-open-in-new"
              class="smoke"
              size="small"
              variant="tonal"
              small
              @click="openHeatmap(h)"
            ></v-btn>
            <div
              class="truncate pa-4 d-flex justify-center align-center"
              style="font-size: 12pt"
            >
              {{ h.title }}
            </div>
            <v-btn
              icon="mdi-delete"
              class="smoke"
              size="small"
              variant="tonal"
              @click="remove(h)"
            ></v-btn>
          </div>
          <div class="px-2 pb-2 d-flex align-center" style="gap: 10px">
            <v-select
              variant="solo"
              bg-color="smoke"
              value="Heatmap"
              flat
              :items="getItems(h)"
              hide-details=""
              v-model="h.variant"
              @change="addVideoKey(index)"
            ></v-select>
          </div>
          <div
            style="width: 100%"
            class="d-flex justify-center"
            v-if="h.type === 'image'"
            :key="index === 0 ? imageKey0 : index === 1 ? imageKey1 : imageKey2"
          >
            <div style="position: relative">
              <div
                v-for="(a, i) in h.areas_of_interest"
                :key="i"
                :style="{
                  width: h.ratio * a.width + 'px',
                  height: h.ratio * a.height + 'px',
                  top: h.ratio * a.top + 'px',
                  left: h.ratio * a.left + 'px',
                  opacity: aoi ? 1 : 0,
                }"
                style="
                  position: absolute;
                  z-index: 9;

                  border: 3px dashed #00d38c;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <p class="pb-0 ma-0" style="font-size: 8pt">
                  {{ Math.floor(a.value * 100) }}%
                </p>
              </div>
              <img
                :src="getSrc(h)"
                ref="imageRef"
                alt=""
                style="max-height: 320px"
              />
            </div>
          </div>
          <div
            class="d-flex px-4 align-center flex-wrap"
            style="gap: 10px"
            v-if="h.type === 'image'"
          >
            <v-switch
              color="success"
              label="AOI's"
              v-model="aoi"
              hide-details
            ></v-switch>
            <div v-if="aoi" class="d-flex" style="gap: 10px">
              <v-chip v-for="(a, i) in h.areas_of_interest" :key="i"
                >{{ a.name }}: {{ Math.floor(a.value * 100) }}%</v-chip
              >
            </div>
          </div>
          <div
            v-if="h.type === 'video'"
            :key="index === 0 ? videoKey0 : index === 1 ? videoKey1 : videoKey2"
          >
            <video
              :src="getSrc(h)"
              ref="vid"
              @ended="playing = false"
              width="100%"
              @timeupdate="timeUpdate"
              @canplay="setVideoDuration($event, index)"
              style="display: block; max-height: 320px"
              muted
              :controls="!sync"
            ></video>
          </div>

          <!-- <div v-if="!sync" class="d-flex pa-2 align-center" style="gap: 10px">
            <v-btn icon @click="play"
              ><v-icon>{{ playing ? "mdi-pause" : "mdi-play" }}</v-icon></v-btn
            >
            <v-slider
              @change="setCurrentTime"
              :key="key"
              hide-details=""
              v-model="currentTime"
              :max="videoDuration"
              min="0"
              step="0.5"
            ></v-slider>
          </div> -->
          <v-divider></v-divider>

          <v-list class="py-0 my-0">
            <v-list-item
              link
              class="px-4"
              @click="chart = h.type === 'video' ? !chart : chart"
            >
              <div class="d-flex align-center justify-space-between">
                <div class="d-flex">
                  <v-chip
                    label
                    class="mr-2 font-weight-medium"
                    variant="flat"
                    color="accent"
                    >Clarity</v-chip
                  >
                  <transition name="fade">
                    <v-btn-toggle
                      v-model="chartType"
                      borderless
                      density="compact"
                      v-if="chart && h.type === 'video'"
                    >
                      <v-btn @click.stop="toggle" small value="focus">
                        Focus
                      </v-btn>
                      <v-btn @click.stop="toggle" small value="proximity">
                        Proximity
                      </v-btn>
                    </v-btn-toggle>
                  </transition>
                </div>

                <div>{{ h.clarity_score_percentile }}</div>
              </div>
            </v-list-item>
            <v-expand-transition v-if="h.type === 'video'">
              <div
                class="mx-4 mb-2 smoke"
                style="border-radius: 5px"
                v-if="chart"
              >
                <Chart
                  class="py-2"
                  :currentTime="currentTime"
                  :duration="videoDuration"
                  :type="chartType"
                  :metrics="h.clarity_graph"
                />
              </div>
            </v-expand-transition>
            <v-divider></v-divider>
            <v-list-item v-if="attentionScore(h)" link class="px-4">
              <div class="d-flex align-center justify-space-between">
                <v-chip
                  label
                  class="font-weight-medium"
                  variant="flat"
                  color="warning"
                  >Attention</v-chip
                >
                <div>{{ attentionScore(h) }}</div>
              </div>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>
          <v-list class="py-0 my-0" v-if="h.logo_tracking_status === 'done'">
            <v-list-item link class="px-4">
              <div class="d-flex align-center justify-space-between">
                <div style="font-weight: 500">Brand Exposure:</div>
                <div>
                  <span>{{ Math.round(h.metrics.brand_exposure_seconds) }}</span
                  >/{{ Math.round(h.duration) }}s.
                </div>
              </div>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item link class="px-4">
              <div class="d-flex align-center justify-space-between">
                <div style="font-weight: 500">Brand Attention:</div>

                <div>{{ h.metrics.brand_attention_percentage }}%</div>
              </div>
            </v-list-item>
            <!-- <v-divider></v-divider> -->
            <!-- <v-list-item link class="px-4 d-flex align-center">
            <div style="font-weight: 500">Audio mentions:</div>
            <v-spacer></v-spacer>
            <div>2x</div>
          </v-list-item> -->
            <v-divider></v-divider>
            <v-list-item class="py-1" link>
              <div class="d-flex align-center justify-space-between">
                <div style="font-weight: 500">Brand in first 2 sec.</div>

                <v-icon small color="success" v-if="h.metrics.first_2_seconds"
                  >mdi-check-circle</v-icon
                >
                <v-icon v-else small color="error">mdi-close-circle</v-icon>
              </div>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="py-1" link>
              <div class="d-flex align-center justify-space-between">
                <div style="font-weight: 500">Brand in first 5 sec.</div>

                <v-icon small color="success" v-if="h.metrics.first_5_seconds"
                  >mdi-check-circle</v-icon
                >
                <v-icon v-else small color="error">mdi-close-circle</v-icon>
              </div>
            </v-list-item>
          </v-list>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Chart from "./Heatmap/chart/ChartCard.vue";
import firebase from "firebase/";

export default {
  components: {
    Chart,
  },
  data() {
    return {
      aoi: true,
      chart: false,
      currentTime: null,
      chartType: "focus",
      heatmaps: [],
      imageKey0: 0,
      imageKey1: 0,
      imageKey2: 0,
      key: 0,
      playing: false,
      sync: false,
      videoDuration: null,
      videoKey0: 0,
      videoKey1: 0,
      videoKey2: 0,
    };
  },
  beforeUnmount() {
    this.$store.commit("cleanCompare");
    this.$store.commit("closeCompare");
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      show: (state) => state.heatmaps.compareSheet,
      compare: (state) => state.heatmaps.compare,
    }),
  },
  mounted() {
    if (this.compare.length === 0) {
      this.$router.push("/heatmaps");
    } else {
      this.fetchData();
    }
  },
  watch: {
    sync() {
      this.$refs.vid[0].pause();
      this.$refs.vid[1].pause();
      this.$refs.vid[2].pause();

      this.playing = false;

      this.$nextTick(() => {
        this.$refs.sheet.showScroll();
        // either set :retain-focus="false" above or do this:
        this.$nextTick(() => this.$refs.sheet.unbind());
      });
    },
  },
  methods: {
    addVideoKey(i) {
      this[`videoKey${i}`]++;
      this[`imageKey${i}`]++;
    },
    attentionScore(data) {
      if (data.attention_scores && data.attention_scores.length > 0) {
        let sum = 0;
        let count = 0;

        data.attention_scores.forEach((a) => {
          sum = sum + a.score;
          count = count + 1;
        });
        return Math.round(sum / count);
      } else {
        let application = data.application;
        let score;

        if (application === "Other" || application === "UX Design") {
          return null;
        } else if (application === "Display Ads") {
          if (data.template_scores && data.type === "image") {
            const dictionary = data.template_scores.display;
            let sum = 0;
            let count = 0;
            Object.keys(dictionary).forEach(function (key) {
              count++;
              sum = sum + dictionary[key];
            });
            if (count > 0) {
              score = sum / count;
            } else {
              return null;
            }
          } else if (
            data.application === "Display Ads" &&
            data.type === "video" &&
            data.attention_scores &&
            data.attention_scores.length > 0
          ) {
            const att = data.attention_scores;
            let sum = 0;
            let count = 0;

            att.forEach((a) => {
              sum = sum + a.score;
              count = count + 1;
            });

            if (count > 0) {
              score = sum / count;
            } else {
              return null;
            }
          }
        } else if (application === "Social media") {
          if (data.template_scores && data.type === "image") {
            const dictionary = data.template_scores.social;
            let sum = 0;
            let count = 0;
            Object.keys(dictionary).forEach(function (key) {
              count++;
              sum = sum + dictionary[key];
            });
            if (count > 0) {
              score = sum / count;
            } else {
              return null;
            }
          } else if (
            data.application === "Social media" &&
            data.type === "video" &&
            data.attention_scores &&
            data.attention_scores.length > 0
          ) {
            const att = data.attention_scores;
            let sum = 0;
            let count = 0;

            att.forEach((a) => {
              sum = sum + a.score;
              count = count + 1;
            });
            if (count > 0) {
              score = sum / count;
            } else {
              return null;
            }
          }
        } else if (application === "(Digital) Out Of Home") {
          if (data.template_scores && data.type === "image") {
            const dictionary = data.template_scores.ooh;
            let sum = 0;
            let count = 0;
            Object.keys(dictionary).forEach(function (key) {
              count++;
              sum = sum + dictionary[key];
            });
            if (count > 0) {
              score = sum / count;
            } else {
              return null;
            }
          } else if (
            data.application === "(Digital) Out Of Home" &&
            data.type === "video" &&
            data.attention_scores &&
            data.attention_scores.length > 0
          ) {
            const att = data.attention_scores;
            let sum = 0;
            let count = 0;

            att.forEach((a) => {
              sum = sum + a.score;
              count = count + 1;
            });
            if (count > 0) {
              score = sum / count;
            } else {
              return null;
            }
          }
        }
        return Math.round(score);
      }
    },
    change(v) {
      if (!v) {
        this.sync = false;
      }
    },
    changeSync(v) {
      if (v) {
        this.currentTime = 0;
        this.setCurrentTime();
      }
    },
    async fetchData() {
      for (let x in this.compare) {
        let y = this.$store.state.heatmaps.heatmaps.find(
          (h) => h.uid === this.compare[x]
        );
        if (y != undefined) {
          y.variant = "Heatmap";
          y.key = 0;
        }

        if (y.type === "image") {
          let image = new Image();
          image.onload = function () {
            y.ratio = 320 / image.naturalHeight;
          };

          image.src = y.imageURL;
        }

        if (y.type === "video") {
          // Metrics: Video Clarity Graph
          var videoClarity = firebase
            .app()
            .functions("europe-west1")
            .httpsCallable("videoClarity");
          const videoClarityRes = await videoClarity(JSON.stringify(y));
          y.clarity_graph = videoClarityRes.data;

          // Metrics: Brand Attention
          var brandAttention = firebase
            .app()
            .functions("europe-west1")
            .httpsCallable("brandAttention");
          const brandAttentionRes = await brandAttention(JSON.stringify(y));
          y.brand_attention = brandAttentionRes.data;
        }

        this.heatmaps.push(y);
      }
    },

    getSrc(h, i) {
      if (h.variant === "Heatmap") {
        return h.type === "video" ? h.heatmapURL : h.combined;
      } else if (h.variant === "Peekthrough") {
        return h.type === "video" ? h.peekthroughURL : h.peekthrough;
      } else if (h.variant === "Original") {
        return h.imageURL;
      } else if (h.variant === "Brand detection") {
        return h.logo_tracking_video;
      }
      if (h.type === "image") {
        this[`videoKey${i}`]++;
      }
    },
    getItems(h) {
      let items = ["Heatmap", "Original", "Peekthrough"];
      if (h.logo_tracking_video) {
        items.splice(0, 0, "Brand detection");
      }
      return items;
    },
    openHeatmap(h) {
      window.open(`${window.location.origin}/heatmap/${h.uid}`, "_blank");
    },
    play() {
      if (this.playing) {
        this.playing = false;
        this.$refs.vid[0].pause();
        this.$refs.vid[1].pause();
        this.$refs.vid[2].pause();
      } else {
        this.playing = true;
        this.$refs.vid[0].play();
        this.$refs.vid[1].play();
        this.$refs.vid[2].play();
      }
    },
    remove(h) {
      let i = this.heatmaps.findIndex((heatmap) => heatmap.uid === h.uid);
      if (this.heatmaps.length === 1) {
        this.$router.push("/heatmaps");
      } else {
        this.heatmaps.splice(i, 1);
      }
    },
    setCurrentTime() {
      this.$refs.vid[0].currentTime = String(this.currentTime);
      this.$refs.vid[1].currentTime = String(this.currentTime);
      this.$refs.vid[2].currentTime = String(this.currentTime);
    },
    setVideoDuration(t, i) {
      this.videoDuration = t.srcElement.duration;
      this.key++;
      if (
        this.$refs.vid[i].currentTime === 0 &&
        this.currentTime > 0 &&
        this.sync
      ) {
        this.setCurrentTime();
      }
    },
    timeUpdate(t) {
      this.currentTime = t.srcElement.currentTime;
      console.log(this.currentTime);
      this.videoDuration = t.srcElement.duration;
    },
    toggle(t) {
      console.log(t);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 25px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-flow: column;
  width: 100vw;
  gap: 25px;
  height: calc(100vh - 100px);
}

.truncate {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
